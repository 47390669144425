
  import { defineComponent } from 'vue';
  import { state } from "./store";

  export default defineComponent({
    name: 'App',
    created(){
        document.title = "ManaBase.app"
    },
    data() {
      return {
        state
      }
    }
  });

import { createRouter, createWebHistory } from 'vue-router';
import { SearchType, OrderAttribute, OrderDirection } from '@/classes/scryfallConnector';
import { LandCycleName } from '@/classes/utils';
import Home from '../views/Home.vue'
import { actions } from "../store";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    pathToRegexOptions: { strict: true }
  },
  {
    path: '/cards/:cardId/:printingId?',
    name: 'Cards',
    component: () => import(/* webpackChunkName: "about" */ '../views/CardView.vue'),
    pathToRegexOptions: { strict: true }
  },
  {
    path: '/cycles',
    name: 'Cycles',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cycles.vue'),
    pathToRegexOptions: { strict: true }
  },
  {
    path: '/cycles/:cycleId',
    name: 'CycleView',
    component: () => import(/* webpackChunkName: "about" */ '../views/CycleView.vue'),
    pathToRegexOptions: { strict: true }
  },
  {
    path: '/lists',
    name: 'ManaLists',
    component: () => import(/* webpackChunkName: "about" */ '../views/ManaLists.vue'),
    children: [
      {
        path: '',
        name: 'ManaLists.index',
        component: () => import(/* webpackChunkName: "about" */ '../views/ManaLists.vue'),
      },
    ],
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/lists/:listId',
    name: 'ManaLists.page',
    component: () => import(/* webpackChunkName: "about" */ '../views/ManaList.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {

    // Don't jump to top if only query params changed.
    if(to.path === from.path) {
      return;
    }

    // Hack to make clicking on different prints scroll up.
    if(to.name === 'Cards') {
      document.getElementById('main')?.scroll(0,0);
    }

    // scroll to top
    return { top: 0 }
  },
  routes
});

router.isReady().then(() => {

  if(localStorage.getItem('preferredPrinting')) {
    actions.setPreferredPrinting(localStorage.getItem('preferredPrinting') as string);
  }

  if(localStorage.getItem('currentManaList')) {
    actions.setCurrentManaList(localStorage.getItem('currentManaList') as string);
  }

  if(router.currentRoute.value.query) {
    let cycleList = Array.isArray(router.currentRoute.value.query['cycle']) ? router.currentRoute.value.query['cycle'] as LandCycleName[] : [router.currentRoute.value.query['cycle']]  as LandCycleName[];
    const colorList = router.currentRoute.value.query['color'] as string[];
    const searchList = Array.isArray(router.currentRoute.value.query['search']) ? router.currentRoute.value.query['search'] as SearchType[] : [router.currentRoute.value.query['search']]  as SearchType[];
    const maxPrice = router.currentRoute.value.query['priceMax'] as string;
    if(cycleList[0] === undefined) {
      cycleList = [];
    }
    const orderAttribute = router.currentRoute.value.query['orderAttribute'] as OrderAttribute;
    const orderDirection = router.currentRoute.value.query['orderDirection'] as OrderDirection;
    actions.initFilter(
      orderAttribute ?? 'edhrec',
      orderDirection ?? 'auto',
      colorList ?? [],
      cycleList ?? [],
      router.currentRoute.value.query['colorless'] === 'true',
      router.currentRoute.value.query['untapped'] === 'true',
      router.currentRoute.value.query['advancedSearch'] === 'true',
      parseInt(maxPrice ?? '99999') ,
      searchList ?? []
      );
  }
})




export default router

import { reactive } from 'vue';

import { ScryfallCard } from './classes/scryfallCard';
import { ManaList, ManaListEntry } from './classes/manaLists';
import { LandCycle, LandCycleName } from '@/classes/utils';
import { ScryfallConnector } from './classes/scryfallConnector';
import { ScryfallQuery, SearchType, Order, OrderAttribute, OrderDirection } from './classes/scryfallConnector';
import router from './router';

const connector = new ScryfallConnector();
const resultList:Array<ScryfallCard> = new Array();

interface State {
  advancedSearch: boolean,
  currentManaList?: ManaList,
  resultList: Array<ScryfallCard>,
  searching: boolean,
  selectedSpy: string,
  showQuickScroll: boolean,
  showList: boolean,
  connector: ScryfallConnector,
  hasSearched: boolean,
  preferredPrinting: string,
  listsEnabled: boolean,
  displayCardCount: number
}

// reactive state
export const state:State = reactive({
  advancedSearch: false,
  currentManaList: undefined,
  resultList: resultList,
  searching: false,
  selectedSpy: '',
  showQuickScroll: false,
  showList: false,
  connector: connector,
  hasSearched: false,
  preferredPrinting: 'cheapest',
  listsEnabled: false, // This turns on / off mana lists during development
  displayCardCount: 32,
}) as State;

export const actions = {
  getCycles: async() => {

  },
  doQuery: async () => {

    if(state.connector.filters.color.length > 0) {
      const theQuery = state.connector.getFilterString();
      state.searching = true;

      // Initial query
      state.connector.refreshQueries().then((results) => {
        const newResult = state.connector.getFilterString();
        if(theQuery === newResult) {
          state.resultList = results;
          state.searching = false;
          state.hasSearched = true;
          state.displayCardCount = 32;
          (window as any).plausible('Search', {props: {colors: `${Object.values(state.connector.filters.color).sort().join('')}${state.connector.filters.colorless ? 'C' : ''}`}});
        }
        
      });
    } else {
      state.resultList = [];
      state.searching = false;
    }
  },

  initFilter: (orderAttribute:OrderAttribute, orderDirection:OrderDirection, colors: Array<string>, cycleList: Array<string>, colorless: boolean, untapped: boolean, advancedSearch: boolean, priceMax: number, searchList: Array<SearchType>) => {
    let shouldQuery = false;

    state.connector.order.attribute = orderAttribute;
    state.connector.order.direction = orderDirection;

    for(const cycle of cycleList) {
      state.connector.filters.cycle.add(cycle as LandCycleName);
      shouldQuery = true;
    }

    for(const color of colors) {
      state.connector.setColorFilter(color);
      shouldQuery = true;
    }

    if(colorless) {
      state.connector.setColorlessFilter(colorless);
      shouldQuery = true;
    }

    if(untapped && advancedSearch) {
      state.connector.filters.untapped = untapped;
      shouldQuery = true;
    }

    if(advancedSearch) {
      state.advancedSearch = true;
    }

    for(const search of searchList) {
      if(search in SearchType) {
        state.connector.filters.search.add(search);
        shouldQuery = true;
      }
    }

    if(priceMax) {
      state.connector.filters.priceMax = Number(priceMax);
      actions.emitPriceChange(priceMax);
    }

    if(shouldQuery) {
      actions.doQuery();
    }
  },

  setOrder: (newOrder: Order) => {
    state.connector.order = newOrder;

    router.push({query: getQueryParams()});
  },

  setQueryParams: () => {
    router.push({query: getQueryParams()});
  },

  setScrollTop: () => {
    document.getElementById("main")?.scrollTo(0,0);
  },

  setColorFilter: (color:string) => {
    (window as any).plausible('Color - Click', {props: {name: color}});
    if(color === "C") {
      state.connector.setColorlessFilter(!state.connector.filters.colorless);
    } else {
      state.connector.setColorFilter(color);
    }

    // Removes invalid searches
    if(state.connector.filters.search.has(SearchType.ProducesW) && !state.connector.filters.color.includes('W')) {
      state.connector.filters.search.delete(SearchType.ProducesW);
    }

    if(state.connector.filters.search.has(SearchType.ProducesU) && !state.connector.filters.color.includes('U')) {
      state.connector.filters.search.delete(SearchType.ProducesU);
    }

    if(state.connector.filters.search.has(SearchType.ProducesB) && !state.connector.filters.color.includes('B')) {
      state.connector.filters.search.delete(SearchType.ProducesB);
    }

    if(state.connector.filters.search.has(SearchType.ProducesR) && !state.connector.filters.color.includes('R')) {
      state.connector.filters.search.delete(SearchType.ProducesR);
    }

    if(state.connector.filters.search.has(SearchType.ProducesG) && !state.connector.filters.color.includes('G')) {
      state.connector.filters.search.delete(SearchType.ProducesG);
    }

    router.push({query: getQueryParams()});
  },

  setUntappedFilter: (newState: boolean) => {
    state.connector.filters.untapped = newState;
    router.push({query: getQueryParams()});
  },

  setPriceFilter: (priceMax:number) => {
    state.connector.filters.priceMax = Number(priceMax);

    router.push({query: getQueryParams()});
  },

  setPreferredPrinting: (newPrinting:string) => {
    state.preferredPrinting = newPrinting;
    localStorage.setItem('preferredPrinting', newPrinting);

    (window as any).plausible('Perferred Printing - Set', {props: {name: newPrinting}});
  },

  setDisplayCardCount: (newCount:number) => {
    state.displayCardCount = newCount;
  },

  setAdvancedSearch: (newState:boolean) => {
    state.advancedSearch = newState;

    setTimeout(() => {
      if(!newState) {
        actions.setPriceFilter(99999);
        actions.setUntappedFilter(false);
        actions.emitPriceChange(99999);
      }
      router.push({query: getQueryParams()});
    }, 400);

  },

  emitPriceChange: (newPrice: number) => {
    const el = document.getElementById('priceControl');
    el?.dispatchEvent(new CustomEvent('resetPrice', {
      detail : {
        newPrice: newPrice
      }
    }));
  },

  createManaList: () => {
    const newId = (Math.random() + 1).toString(36).substring(7);
    const newList = new ManaList(newId);
    localStorage.setItem(`list--${newId}`, JSON.stringify(newList, replacer));
    actions.setCurrentManaList(newId);
  },

  setCurrentManaList: (id:string) => {
    try {
      const list = getManaList(id);
      if(list) {
        state.currentManaList = list;
        localStorage.setItem(`currentManaList`, list.id);
        console.log(state.currentManaList);
        return list;
      }
    } catch {
      return null;
    }

    return null;
  },

  saveManaList: (manaList:ManaList) => {
    // Necessary to deep copy the map
    const tmpManaList = Object.assign({}, manaList);
    tmpManaList.cardList = new Map(JSON.parse(
      JSON.stringify(Array.from(manaList.cardList))
    ));

    tmpManaList.cardList.forEach((val:ManaListEntry, key:string) => {
      delete val.card;
    });

    localStorage.setItem(`list--${manaList.id}`, JSON.stringify(tmpManaList, replacer));
    // Forces reactivity
    manaList.cardList = new Map(manaList.cardList);
  },

  addCardToManaList: (card:ScryfallCard, preferredPrintingId="") => {
    if(state.currentManaList) {
      state.currentManaList.addCard(card, preferredPrintingId);
      actions.saveManaList(state.currentManaList as ManaList);

      (window as any).plausible('CardList - Add', {props: {name: card.name}});      
    }
  },

  removeCardFromManaList: (card:ScryfallCard) => {
    if(state.currentManaList) {
      state.currentManaList.removeCard(card);
      localStorage.setItem(`list--${state.currentManaList.id}`, JSON.stringify(state.currentManaList, replacer));
      // Forces reactivity
      state.currentManaList.cardList = new Map(state.currentManaList.cardList);

      (window as any).plausible('CardList - Remove', {props: {name: card.name}});
    }
  },

  toggleList: () => {
    state.showList = !state.showList;

    // Hide the underlay scroll bar
    if(state.showList) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'overlay';
    }
  },

  clearFilters: () => {
    state.connector.filters.search.clear();
    state.connector.filters.cycle.clear();

    router.push({query: getQueryParams()});
  },

  toggleSearch: (search: SearchType) => {
    (window as any).plausible('Search - Click', {props: {name: search}});
    if(state.connector.filters.search.has(search)) {
      state.connector.filters.search.delete(search);
    } else {
      state.connector.filters.search.add(search);
    }

    router.push({query: getQueryParams()});
  },

  toggleCycle: (cycle: LandCycleName) => {
    (window as any).plausible('Cycle - Click', {props: {name: cycle}});
    if(state.connector.filters.cycle.has(cycle)) {
      state.connector.filters.cycle.delete(cycle);
    } else {
      state.connector.filters.cycle.add(cycle);
    }

    router.push({query: getQueryParams()});
  },

  updateScrollSpy: (position:number) => {
    const scrollElement = document.getElementById("main");
    const scrollTop = scrollElement?.scrollTop ?? 0;
    let highestElement = null;
    const resultList = document.querySelectorAll('.queryResultArea');

    if(scrollTop > 350) {
      state.showQuickScroll = true;
    } else {
      state.showQuickScroll = false;
    }

    for(const x of resultList) {
      if(highestElement === null) {
        highestElement = x;
      } else {
        if((x as HTMLElement).offsetTop < scrollTop+350) {
          highestElement = x;
        }
      }
    }

    if(highestElement?.id) {
      const elementName = highestElement!.id.split('-')[0];
      const elementId = `${elementName ?? ''}-spy`;

      const spyList = document.querySelectorAll('.queryResultSpy');
      state.selectedSpy = elementId;
    }
  }
}

export function getManaList(id:string):ManaList|null {
  try {
    const list = localStorage.getItem(`list--${id}`) as string;
    const manaList = ManaList.createFromJSON(JSON.parse(list));
    manaList.cardList.forEach((val:ManaListEntry, key:string) => {
      val.card = getCardFromId(key);
    });
    return manaList;
  } catch {
    return null;
  }
  return null;
  
}

export function getCardFromId(id:string):ScryfallCard|null {
  return state.connector.allCards.get(id) ?? null;
}

function getQueryParams() {
  const searchParams = [...state.connector.filters.search].filter(x => x != SearchType.Standard);
  const cycleParams = [...state.connector.filters.cycle];
  return {
    color: state.connector.filters.color.filter(c => c !== 'C'),
    colorless:  state.connector.filters.colorless ? 'true' : undefined,
    untapped: state.advancedSearch && state.connector.filters.untapped ? 'true' : undefined,
    search: searchParams,
    cycle: cycleParams,
    priceMax: state.connector.filters.priceMax !== 99999 ? String(state.connector.filters.priceMax) : undefined,
    advancedSearch: state.advancedSearch ? 'true' : undefined,
    orderAttribute: state.connector.order.attribute,
    orderDirection: state.connector.order.direction,
  }
}

export function getManaLists() {
  try {
    const localStorageEntries = Object.entries({...localStorage}).filter(([key]) => key.includes('list--'));
    const items = [];
    for(const item of localStorageEntries) {
      console.log(item);
      items.push(ManaList.createFromJSON(JSON.parse(item[1])));
    }
    return items;
  } catch {
    console.log('oops');
  }
  return new Array();
}

function replacer(key:string, value:any) {
  if(value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}
function reviver(key:string, value:any) {
  if(typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}
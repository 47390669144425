import axios from 'axios';
import { ScryfallCard } from './scryfallCard';
import { LandCycle, LandCycleName } from './utils';
const VISUAL_QUERY = 'prefer:usd-low';
const BASE_QUERY = `https://api.scryfall.com/cards/search?q=${VISUAL_QUERY} (-set:cmb1 -set:cmb2 type:land game:paper lang:en -is:datestamped is:nonfoil -is:promo -is:etched -is:glossy -banned:commander) and `;

import cardsJson from '../data/card_data.json';

interface QueryMap<T> {
    [index: string]: T;
}

interface Filter {
  cycle: Set<LandCycleName>,
  color: Array<string>,
  colorless: boolean,
  untapped: boolean,
  search: Set<SearchType>,
  priceMax: Number,
  priceMin: Number,
}

export type OrderAttribute = 'edhrec'|'price'|'name'|'production';
export type OrderDirection = 'desc'|'asc'|'auto';

export interface Order {
  attribute: OrderAttribute,
  direction: OrderDirection
}

const DEFAULT_ENABLE_QUERY_FUNCTION = () => true;

export enum ManaColors {
  White = 'W',
  Blue = 'U',
  Black = 'B',
  Red = 'R',
  Green = 'G'
}

export enum SearchType {
  Produces = 'Produces',
  Utility = 'Utility',
  ProducesExactly = 'ProducesExactly',
  ProducesOneOf = 'ProducesOneOf',
  ProducesTwoOf = 'ProducesTwoOf',
  ProducesThreeOf = 'ProducesThreeOf',
  ProducesExtra = 'ProducesExtra',
  ProducesAll = 'ProducesAll',
  ProducesW = 'ProducesW',
  ProducesU = 'ProducesU',
  ProducesB = 'ProducesB',
  ProducesR = 'ProducesR',
  ProducesG = 'ProducesG',
  Untapped = 'Untapped',
  UntappedSometimes = 'UntappedSometimes',
  Fetches = 'Fetches',
  ActivatedAbility = 'ActivatedAbility',
  EtbAbility = 'EtbAbility',
  StaticAbility = 'StaticAbility',
  HandAbility = 'HandAbility',
  Creature = 'Creature',
  Spell = 'Spell',
  Standard = 'Standard',
  IsBasic = 'Basic Type',
  PriceCheap = 'PriceCheap',
  PriceModerate = 'PriceModerate',
  PriceModest = 'PriceModest',
  PricePricey = 'PricePricey',
  PriceExpensive = 'PriceExpensive',
  PriceLavish = 'PriceLavish',
}

export class ScryfallQuery {
  queryName: string;
  queryId: SearchType;
  queryGroup: string;
  queryString: string;
  fetchingPromise: Promise<Object>|undefined;
  shouldEnableQueryFunction: Function;
  results: Array<ScryfallCard>;
  searching: boolean;
  queryAbortController: AbortController;

  constructor(queryName:string, queryId:SearchType, queryGroup:string, queryString: string, shouldEnableQueryFunction?: Function ) {
    this.queryName = queryName;
    this.queryId = queryId;
    this.queryGroup = queryGroup;
    this.queryString = queryString;
    this.results = [];
    this.shouldEnableQueryFunction = shouldEnableQueryFunction ?? DEFAULT_ENABLE_QUERY_FUNCTION;
    this.searching = false;
    this.queryAbortController = new AbortController();
  }

  async abortQuery() {
    this.queryAbortController.abort();
  }

  async doQuery():Promise<Array<ScryfallCard>> {

    this.shouldEnableQueryFunction = () => true;

    if(!this.shouldEnableQueryFunction()) {
      return [];
    }

    let data = new Array();
    try {
      this.searching = true;
      this.fetchingPromise = axios.get(this.queryString, {
        signal: this.queryAbortController.signal
      });

      // Pagination
      let response = ((await this.fetchingPromise) as any);
      data = data.concat(response['data']['data']);
      while(response['data']['has_more']) {
        this.fetchingPromise = axios.get(response['data']['next_page']);
        response = ((await this.fetchingPromise) as any);
        data = data.concat(response['data']['data']);
      }

    } catch (e) {
      console.error(e);
      console.error("OH NO");
      data = []
    } finally {
      this.searching = false;
    }

    const newCardList = data;
    const retList = [];

    for(const newCard of newCardList as Iterable<unknown>) {
      const tmpCard = JSON.parse(JSON.stringify(newCard));

      // Ignore non-mdfcs if their face isn't a land
      if(!['normal', 'modal_dfc'].includes(tmpCard?.layout) && tmpCard.card_faces) {
        if(!tmpCard?.card_faces[0]?.type_line.includes('Land')) {
          continue;
        }
      }

      const card:ScryfallCard = new ScryfallCard(JSON.stringify(tmpCard));
      card.addPrinting(tmpCard);
      retList.push(card);
    }

    this.results = retList;
    return retList;
  }

  getResults():Array<ScryfallCard> {
    return this.results;
  }
}

export class ScryfallConnector {
  searching: boolean;
  filters: Filter;
  order: Order;
  queryList: Array<ScryfallQuery>;

  genericList!: ScryfallQuery;
  standardList!: ScryfallQuery;

  fetchList!: ScryfallQuery;
  utilityList!: ScryfallQuery;

  producesExactList!: ScryfallQuery;
  producesSomeList!: ScryfallQuery;
  producesExtraList!: ScryfallQuery;
  untappedList!: ScryfallQuery;
  producesAllList!: ScryfallQuery;
  creatureList!: ScryfallQuery;
  spellList!: ScryfallQuery;

  allCards: Map<string, ScryfallCard>;


  constructor() {
    this.searching = false;
    this.filters = {
      color: [],
      colorless: false,
      untapped: false,
      search: new Set(),
      cycle: new Set(),
      priceMax: 99999,
      priceMin: 0
    };

    this.order = {
      attribute:'edhrec',
      direction: 'auto'
    };

    this.queryList = [];
    
    this.allCards = new Map();
    const cardData:Array<ScryfallCard> = cardsJson.map(x => new ScryfallCard((x[1] as unknown), 'blob'));
    cardData.map((x:ScryfallCard) => this.allCards.set(x.name, x));



    //this.refreshQueries();
  }

  getFilterString():string {
    const obj:any = Object.assign({}, this.filters);
    obj.color = obj.color.sort();
    obj.search.delete('Standard');
    obj.search = [...obj.search].sort();
    obj.cycle = [...obj.cycle].sort();
    return JSON.stringify(obj);
  }

  async refreshQueries():Promise<Array<ScryfallCard>> {
    // Conditionally trigger the "standard" search
    if(this.filters.search.size === 0 || (this.filters.search.size === 1 && this.filters.search.has(SearchType.Standard))) {
      this.filters.search.add(SearchType.Standard);
    } else {
      this.filters.search.delete(SearchType.Standard); 
    }

    //let startList = resultList;
    let startList = [...this.allCards.values()];
    let filterList = new Array<Function>();
    startList = startList.filter((a) => {
      if(this.filters.priceMax < 9999) {
        return a.getPrinting('cheapest').price_usd < this.filters.priceMax && a.getPrinting('cheapest').price_usd > this.filters.priceMin 
      } else {
        return true;
      }
    });

    console.log([...this.filters.color]);
    console.log([...startList[0].color_identity]);
   
    this.filters.color.length > 0 ? filterList.push((x:ScryfallCard) => x.color_identity.length <= this.filters.color.length && x.color_identity.length > 0 && [...x.color_identity].every((color) => [...this.filters.color].includes(color))) : '';
    this.filters.colorless ? filterList.push((x:ScryfallCard) => x.color_identity.length === 0) : '';

    startList = startList.filter((x:ScryfallCard) => filterList.some((y:Function) => y(x)));
    filterList = [];

    // Production filtering
    this.filters.search.has(SearchType.Produces) ? filterList.push((x:ScryfallCard) => x.produces.some(x => this.filters.color.includes(x))) : '';

    this.filters.search.has(SearchType.ProducesExactly) ? filterList.push((x:ScryfallCard) => x.produces.sort().join(',').toLowerCase() === this.filters.color.sort().join(',').toLowerCase()) : '';
    this.filters.search.has(SearchType.ProducesAll) ? filterList.push((x:ScryfallCard) => x.produces.length === 5) : '';
    this.filters.search.has(SearchType.ProducesOneOf) ? filterList.push((x:ScryfallCard) => x.produces.some(x => this.filters.color.includes(x)) && x.produces.length === 1) : '';
    this.filters.search.has(SearchType.ProducesTwoOf) ? filterList.push((x:ScryfallCard) => x.produces.length == 2) : '';
    this.filters.search.has(SearchType.ProducesThreeOf) ? filterList.push((x:ScryfallCard) => x.produces.length == 3) : '';


    this.filters.search.has(SearchType.ProducesW) ? filterList.push((x:ScryfallCard) => x.produces.includes('W') && x.produces.length === 1) : '';
    this.filters.search.has(SearchType.ProducesU) ? filterList.push((x:ScryfallCard) => x.produces.includes('U') && x.produces.length === 1) : '';
    this.filters.search.has(SearchType.ProducesB) ? filterList.push((x:ScryfallCard) => x.produces.includes('B') && x.produces.length === 1) : '';
    this.filters.search.has(SearchType.ProducesR) ? filterList.push((x:ScryfallCard) => x.produces.includes('R') && x.produces.length === 1) : '';
    this.filters.search.has(SearchType.ProducesG) ? filterList.push((x:ScryfallCard) => x.produces.includes('G') && x.produces.length === 1) : '';

    // Utility filtering
    this.filters.search.has(SearchType.Utility) ? filterList.push((x:ScryfallCard) => x.isUtility) : '';

    this.filters.search.has(SearchType.Creature) ? filterList.push((x:ScryfallCard) => x.tag_set.isCreatureLand) : '';
    this.filters.search.has(SearchType.Spell) ? filterList.push((x:ScryfallCard) => x.tag_set.isSpellLand) : '';
    this.filters.search.has(SearchType.Fetches) ? filterList.push((x:ScryfallCard) => x.tag_set.isFetchLand) : '';
    this.filters.search.has(SearchType.ActivatedAbility) ? filterList.push((x:ScryfallCard) => x.tag_set.isActivatedAbility) : '';
    this.filters.search.has(SearchType.StaticAbility) ? filterList.push((x:ScryfallCard) => x.tag_set.isStaticAbility) : '';
    this.filters.search.has(SearchType.EtbAbility) ? filterList.push((x:ScryfallCard) => x.tag_set.isEtbAbility) : '';
    this.filters.search.has(SearchType.HandAbility) ? filterList.push((x:ScryfallCard) => x.tag_set.isHandAbility) : '';

    // Tag filtering
    this.filters.search.has(SearchType.ProducesExtra) ? filterList.push((x:ScryfallCard) => x.tag_set.producesExtra) : '';
    this.filters.search.has(SearchType.IsBasic) ? filterList.push((x:ScryfallCard) => x.tag_set.isBasic) : '';
    this.filters.search.has(SearchType.Untapped) ? filterList.push((x:ScryfallCard) => x.tag_set.entersUntappedAlways) : '';
    this.filters.search.has(SearchType.UntappedSometimes) ? filterList.push((x:ScryfallCard) => x.tag_set.entersUntappedSometimes) : '';

    // Cycle Filtering
    for(const cycle of this.filters.cycle) {
      filterList.push((x:ScryfallCard) => x?.cycle?.name === cycle);
    }

    if(filterList.length) {
      startList = startList.filter((x:ScryfallCard) => filterList.some((y:Function) => y(x)));
    }

    


    if(this.order.attribute === 'production') {
      startList = startList.sort(this._productionSort);

      if(this.order.direction === 'asc') {
        startList.reverse();
      }
    } else if(this.order.attribute === 'edhrec') {
      startList.sort((a,b) => b.edhrec_rank - a.edhrec_rank);
      if(this.order.direction === 'asc' || this.order.direction === 'auto') {
        startList.reverse();
      }
    } else if(this.order.attribute === 'name') {
      startList.sort((a,b) => b.name > a.name ? 1 : -1);
      if(this.order.direction === 'asc' || this.order.direction === 'auto') {
        startList.reverse();
      }
    } else if(this.order.attribute === 'price') {
      startList.sort((a,b) => b.getPrinting('cheapest').price_usd - a.getPrinting('cheapest').price_usd);
      if(this.order.direction === 'asc') {
        startList.reverse();
      }
    }

    console.log(startList);

    return startList;
  }

  adjustColors(colorList:Array<ManaColors>, includeColorless:boolean):string {
    if(colorList.length >= 1) {
      return `identity:${colorList.join('')} ${includeColorless ? '' : 'identity>=1'}`;
    } else {
      return '';
    }
  }


  adjustCommanderIdentity(colorList: Array<ManaColors>) {
    if(colorList.length >= 1) {
      return `commander:${this.filters.color.join('')}`;
    } else {
      return '';
    }
  }

  buildBasic(colorList: Array<ManaColors>):string {
    const landList = new Array();

    if(colorList.includes(ManaColors.White)) {
      landList.push('Plains');
    }

    if(colorList.includes(ManaColors.Blue)) {
      landList.push('Island');
    }

    if(colorList.includes(ManaColors.Black)) {
      landList.push('Swamp');
    }

    if(colorList.includes(ManaColors.Red)) {
      landList.push('Mountain');
    }

    if(colorList.includes(ManaColors.Green)) {
      landList.push('Forest');
    }
    return ` or (type:land border:black o:/search your library for .*(${landList.join('|')})/)`;
  }

  buildOrder():string {
    let order:string = this.order.attribute;
    let direction:string = this.order.direction;

    if(direction === 'auto') {
      switch(order) {
        case 'edhrec':
          direction = 'asc';
          break;
        case 'price':
          direction = 'desc';
          break;
        case 'production':
          direction = 'desc';
          break;
        case 'name':
          direction = 'asc';
          break;
        default:
          direction = 'asc';
          break;
      }
    }

    if(order === 'price') {
      order = 'usd';
    }

    // Because scryfall doesn't order by production, default the query.
    if(order === 'production') {
      order = 'edhrec';
    }


    return ` order:${order} dir:${direction}`;
  }

  buildStandardQuery():string {
    return `${BASE_QUERY} (${this.adjustColors(this.filters.color as Array<ManaColors>, this.filters.colorless)} ${this.adjustCommanderIdentity(this.filters.color as Array<ManaColors>)} ${this.buildBasic(this.filters.color as Array<ManaColors>)}) ${this.buildOrder()}`;
  }

  setColorFilter(color:string) {
    if(this.filters.color.includes(color)) {
      this.filters.color = this.filters.color.filter(color2 => color2 !== color);
      if(this.filters.color.length < 2) {
        this.filters.search.delete(SearchType.ProducesOneOf);
      }
      if(this.filters.color.length < 3) {
        this.filters.search.delete(SearchType.ProducesTwoOf);
      }
      if(this.filters.color.length < 4) {
        this.filters.search.delete(SearchType.ProducesThreeOf);
      }
    } else {
      this.filters.color.push(color);
    }
  }

  setColorlessFilter(value:boolean) {
    this.filters.colorless = value;
  }

  isSearching() {
    return this.standardList.searching;
  }

  _productionSort(cardA:ScryfallCard, cardB:ScryfallCard) {

    const produces = cardB.produces.length - cardA.produces.length;

    if(!produces) {
      return cardA.edhrec_rank - cardB.edhrec_rank;
    } else {
      return produces;
    }
  }
}

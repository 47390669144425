import Vue from 'vue';

import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import InlineSvg from 'vue-inline-svg';
import { state, actions } from "./store";

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faRedo,
  faArrowUp,
  faCircleNotch,
  faHandPaper,
  faHandHoldingMagic,
  faQuestion,
  faCompass,
  faEdit,
  faTelescope,
  faDollarSign,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faLongArrowAltDown,
  faSeedling,
  faScrollOld,
  faSun,
  } from '@fortawesome/pro-regular-svg-icons';
import {
  faBallPile,
  faCheck,
  faClipboard,
  faPlus,
  faShoppingCart,
  faMinus,

  faTimes,
  faTrash,
  faWrench } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowUp as any);
library.add(faBallPile as any);
library.add(faCheck as any);
library.add(faCircleNotch as any);
library.add(faClipboard as any);
library.add(faCompass as any);
library.add(faEdit as any);
library.add(faHandHoldingMagic as any);
library.add(faMinus as any);
library.add(faPlus as any);
library.add(faRedo as any);
library.add(faScrollOld as any);
library.add(faSpinner as any);
library.add(faLongArrowAltLeft as any);
library.add(faLongArrowAltRight as any);
library.add(faQuestion as any);
library.add(faShoppingCart as any);
library.add(faSeedling as any);
library.add(faTelescope as any);
library.add(faTimes as any);
library.add(faTrash as any);
library.add(faDollarSign as any);
library.add(faWrench as any);

createApp(App).component('inline-svg', InlineSvg).component("font-awesome-icon", FontAwesomeIcon).use(router).mount('#app');

if(process.env.NODE_ENV === 'production') {
  console.log = function() {};
  console.warn = function() {};
  console.error = function() {};

  // Add plausible tracking only in prod
  const plausible = document.createElement('script');
  plausible.setAttribute('src',"https://plausible.io/js/plausible.js");
  plausible.setAttribute('data-domain',"manabase.app");
  document.head.appendChild(plausible);

  (window as any).plausible = (window as any).plausible || function() { ((window as any).plausible.q = (window as any).plausible.q || []).push() }
} else {
  (window as any).plausible = function(){};
}

const scrollElement = document.getElementById('main');

if(scrollElement) {
  window.onscroll = () => actions.updateScrollSpy(scrollElement.scrollTop);
  window.onload = () => actions.updateScrollSpy(scrollElement.scrollTop);
  window.onresize = () => actions.updateScrollSpy(scrollElement.scrollTop);  
}

export type CycleColor = 'single'|'dual'|'triple';
export type LandCycleName = 'artifactDual'|'battleLand'|'bicycleLand'|'bondLand'|'bounceLand'|'campusLand'|'checkLand'|'creatureDual'|'dualLand'|'fetchLand'|'gainLand'|'fastLand'|'coloredFilterLand'|'colorlessFilterLand'|'painLand'|'sacDrawLand'|'sacFetchLand'|'shockLand'|'slowLand'|'templeLand'|'tricycle'|'triFetchLand'|'triTapLand'|'revealLand'|'lairLand';
const manaSymbol = `({W}|{U}|{B}|{R}|{G}|{C})`;
const basicLand = `(Plains|Island|Swamp|Mountain|Forest)`;

export interface LandCycle {
  name: LandCycleName,
  color: CycleColor,
  display: string,
  priority: number,
  regex: RegExp,
  otherTest?: Function
}

const artifactDual:LandCycle = {
  name: 'artifactDual',
  display: 'Artifact Dual',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.*enters the battlefield tapped.\nIndestructible\n{T}: Add.*`, 'ig')
};

const battleLand:LandCycle = {
  name: 'battleLand',
  display: 'Battle Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.* enters the battlefield tapped unless you control two or more basic lands.`, 'ig')
};

const bicycleLand:LandCycle = {
  name: 'bicycleLand',
  display: 'Bicycle Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`{T}: Add ${manaSymbol} or ${manaSymbol}.*\n.*enters the battlefield tapped.*\n.*cycling \\{2\\}.*`, 'ig')
};

const bondLand:LandCycle = {
  name: 'bondLand',
  display: 'Bond Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.* enters the battlefield tapped unless you have two or more opponents.`, 'ig')
};

const bounceLand:LandCycle = {
  name: 'bounceLand',
  display: 'Bounce Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.* enters the battlefield tapped.\n.*When .* enters the battlefield, return a land you control to its owner's hand.`, 'ig')
};

const campusLand:LandCycle = {
  name: 'campusLand',
  display: 'Campus Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.* enters the battlefield tapped.\n.*{T}: Add ${manaSymbol} or ${manaSymbol}.*\n.*\\{4\\}, {T}: Scry 1`, 'ig')
};

const checkLand:LandCycle = {
  name: 'checkLand',
  display: 'Check Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.* enters the battlefield tapped unless you control (a|an) ${basicLand} or (a|an) ${basicLand}.\n{t}: Add ${manaSymbol} or ${manaSymbol}.*`, 'ig')
};

const creatureDual:LandCycle = {
  name: 'creatureDual',
  display: 'Creature Dual',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.*enters the battlefield tapped.\n{T}: Add.* or .*\n.*:.*becomes a .* creature.*`, 'ig')
};

const dualLand:LandCycle = {
  name: 'dualLand',
  display: 'Dual Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`^\\({T}: Add ${manaSymbol} or ${manaSymbol}.\\)$`, 'gi')

};

const fastLand:LandCycle = {
  name: 'fastLand',
  display: 'Fast Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`enters the battlefield tapped unless you control two or fewer other lands`, 'gi')
};

const coloredFilterLand:LandCycle = {
  name: 'coloredFilterLand',
  display: 'Colored Filter Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`{T}: Add {C}.\n{[a-z]/[a-z]}, {T}: add ${manaSymbol}${manaSymbol}, ${manaSymbol}${manaSymbol}, or ${manaSymbol}${manaSymbol}`, 'gi')
};

const colorlessFilterLand:LandCycle = {
  name: 'colorlessFilterLand',
  display: 'Colorless Filter Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`\\{1\\}, {T}: Add ${manaSymbol}${manaSymbol}`, 'gi')
};

const fetchLand:LandCycle = {
  name: 'fetchLand',
  display: 'Fetch Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`{t}, pay 1 life, sacrifice .*: search your library for (a|an) ${basicLand} or ${basicLand} card, put it onto the battlefield, then shuffle.`, 'gi')
};

const gainLand:LandCycle = {
  name: 'gainLand',
  display: 'Gain Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.* enters the battlefield tapped.\n.*When .* enters the battlefield, you gain 1 life.\n{T}: Add ${manaSymbol} or ${manaSymbol}`, 'gi')
};

const painLand:LandCycle = {
  name: 'painLand',
  display: 'Pain Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`^{T}: add {C}.\n{T}: Add ${manaSymbol} or ${manaSymbol}.* deals 1 damage to you.$`, 'gi')
};

const revealLand:LandCycle = {
  name: 'revealLand',
  display: 'Reveal Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`as .* enters the battlefield, you may reveal (a|an) ${basicLand} or ${basicLand} card from your hand. If you don't, .* enters the battlefield tapped.`, 'gi')
};

const sacDrawLand:LandCycle = {
  name: 'sacDrawLand',
  display: 'Sac-Draw Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`{T}: Add ${manaSymbol} or ${manaSymbol}.\n.*\\{2\\}${manaSymbol}${manaSymbol}, {T}, Sacrifice .*: Draw a card.*`, 'gi')
};

const sacFetchLand:LandCycle = {
  name: 'sacFetchLand',
  display: 'Sac-Fetch Land',
  color: 'triple',
  priority: 0,
  regex: new RegExp(/When .* enters the battlefield, sacrifice it. When you do, search your library/gi)
};

const shockLand:LandCycle = {
  name: 'shockLand',
  display: 'Shock Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(/As .* enters the battlefield, you may pay 2 life. If you don't, .* enters the battlefield tapped/gi)
};

const slowLand:LandCycle = {
  name: 'slowLand',
  display: 'Slow Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.* enters the battlefield tapped unless you control two or more other lands.`, 'gi')
};

const templeLand:LandCycle = {
  name: 'templeLand',
  display: 'Temple Land',
  color: 'dual',
  priority: 0,
  regex: new RegExp(`.* enters the battlefield tapped.\n.*When.* enters the battlefield, scry 1.\n.*Add.*or.*`, 'gi')
};

const tricycleLand:LandCycle = {
  name: 'tricycle',
  display: 'Tricycle Land',
  color: 'triple',
  priority: 0,
  regex: new RegExp(`{T}: Add ${manaSymbol}, ${manaSymbol}, or ${manaSymbol}.*\n.*enters the battlefield tapped.*\n.*cycling \\{3\\}.*`, 'ig')
};

const triFetchLand:LandCycle = {
  name: 'triFetchLand',
  display: 'Panorama Land',
  color: 'triple',
  priority: 0,
  regex: new RegExp(`{T}: Add {C}.\n\\{1\\}, {T}, Sacrifice.*Search your library for a basic.* tapped.*`, 'ig')
};

const triTapLand:LandCycle = {
  name: 'triTapLand',
  display: 'Tri-Tap Land',
  color: 'triple',
  priority: 0,
  regex: new RegExp(`.*enters the battlefield tapped.*\n.*{T}: Add ${manaSymbol}, ${manaSymbol}, or ${manaSymbol}.*`, 'ig')
};

const lairLand:LandCycle = {
  name: 'lairLand',
  display: 'Lair Land',
  color: 'triple',
  priority: 0,
  regex: new RegExp(`When .* enters the battlefield, sacrifice it unless you return a non-Lair land.*`, 'ig')
};


export const landCycleList: Array<LandCycle> = [
  artifactDual,
  battleLand,
  bicycleLand,
  bondLand,
  bounceLand,
  campusLand,
  creatureDual,
  checkLand,
  dualLand,
  fastLand,
  fetchLand,
  coloredFilterLand,
  colorlessFilterLand,
  gainLand,
  lairLand,
  painLand,
  revealLand,
  sacDrawLand,
  sacFetchLand,
  shockLand,
  slowLand,
  templeLand,
  tricycleLand,
  triFetchLand,
  triTapLand,
];

export function determineLandCycle(scryfallObj:any, fullOracle:string[]) {
  for(const cycle of landCycleList) {
    if(fullOracle.some((x:string) => x?.match(cycle.regex)) || (cycle.otherTest ? cycle.otherTest(scryfallObj) : false)) {
      return cycle;
    }
  }

  return undefined;
}
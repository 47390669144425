import { determineLandCycle, LandCycle } from './utils';

export interface TagSet {
  entersUntappedAlways?: boolean,
  entersUntappedSometimes?: boolean,
  entersTapped?: boolean,
  isCreatureLand?: boolean,
  isFetchLand?: boolean,
  isSpellLand?: boolean,
  isModal?:boolean,
  isActivatedAbility?: boolean,
  isEtbAbility?: boolean,
  isStaticAbility?: boolean,
  isHandAbility?: boolean,
  producesW?: boolean,
  producesU?: boolean,
  producesB?: boolean,
  producesR?: boolean,
  producesG?: boolean,
  producesC?: boolean,
  producesExtra?: boolean,
  isPlains?: boolean,
  isIsland?: boolean,
  isSwamp?: boolean,
  isMountain?: boolean,
  isForest?: boolean,
  isBasic?: boolean,
}

export interface Printing {
  border_color: 'black'|'white'|'silver'|'gold',
  face_url_list: Array<string>,
  face_url_list_low: Array<string>,
  cover_art: Array<string>,
  id: string,
  card_id: string,
  price_exists: boolean,
  price_usd: number,
  scryfall_url: string,
  set_code: string,
  set_name: string,
  set_release: string,
  released_at: string,
}

export class ScryfallCard {
  id: string = '';
  name: string = '';
  color_identity: Array<string> = [];
  cycle?: LandCycle;
  produces: Array<string> =  [];
  produces_colorless: boolean = false;
  isUtility: boolean = false;
  edhrec_rank: number = 999999;
  face_name_list: Array<string> = [];
  tag_set: TagSet = {};
  printings: Array<Printing> = [];
  type_line: string = '';
  private full_oracle:Array<string> = [];

  constructor(blob:any, type:'scryfall'|'blob'='scryfall') {
    if(type === 'scryfall') {
      const scryfallObj: any = JSON.parse(blob);

      this.id = scryfallObj?.name;
      this.name = scryfallObj?.name;
      this.color_identity = scryfallObj.color_identity;
      this.edhrec_rank = scryfallObj.edhrec_rank;
      this.produces = scryfallObj?.produced_mana?.filter((c:string) => c != 'C').reverse() ?? [];
      this.produces_colorless = scryfallObj?.produced_mana?.indexOf('C') > -1;
      this.isUtility = false;


      const frontText = scryfallObj?.card_faces?.[0]?.type_line?.includes('Land') ? scryfallObj?.card_faces?.[0]?.oracle_text : '';
      const backText = scryfallObj?.card_faces?.[1]?.type_line?.includes('Land') ? scryfallObj?.card_faces?.[1]?.oracle_text : '';
      this.full_oracle = [scryfallObj?.oracle_text?.toLowerCase(), frontText, backText];
      this.type_line = scryfallObj?.type_line;

      this.pathWayHack(this);

      this.generateTags(scryfallObj);
      this.cycle = determineLandCycle(scryfallObj, this.full_oracle);      
    } else if(type === 'blob') {
      Object.assign(this, blob);
    }
  }


  addPrinting(scryfallObj:any) {
    const printing:Printing =  {
      id: scryfallObj.id,
      card_id: scryfallObj?.name,
      set_code: scryfallObj?.set,
      set_name: scryfallObj?.set_name,
      set_release: scryfallObj?.released_at,
      price_usd: scryfallObj?.prices?.usd ? parseFloat(scryfallObj?.prices?.usd) : 999999,
      price_exists: scryfallObj?.prices?.usd ? true : false,
      face_url_list: scryfallObj?.card_faces?.length > 1 ? [scryfallObj?.card_faces[0].image_uris?.normal, scryfallObj?.card_faces[1].image_uris?.normal] : [scryfallObj?.image_uris?.normal],
      face_url_list_low: scryfallObj?.card_faces?.length > 1 ? [scryfallObj?.card_faces[0].image_uris?.small, scryfallObj?.card_faces[1].image_uris?.small] : [scryfallObj?.image_uris?.small],
      cover_art: scryfallObj?.card_faces?.length > 1 ? [scryfallObj?.card_faces[0].image_uris?.art_crop, scryfallObj?.card_faces[1].image_uris?.art_crop] : [scryfallObj?.image_uris?.art_crop],
      scryfall_url: scryfallObj.scryfall_uri,
      border_color: scryfallObj.border_color,
      released_at: scryfallObj.released_at,
    }
    this.printings.push(printing);
    return printing;
  }

  getPrinting(method:string, id:string=""):Printing {
    if(method === 'cheapest') {
      const a = this.printings.reduce((prev:Printing, current:Printing) => (prev.price_usd < current.price_usd) ? prev : current);
      return a;
    } else if(method === 'oldest') {
      return this.printings.sort((a, b) => a.released_at.localeCompare(b.released_at))[0]
    } else if(method === 'newest') {
      return this.printings.sort((a, b) => b.released_at.localeCompare(a.released_at))[0]
    } else if(method === 'printingId') {

      if(id) {
        const finalPrinting = this.printings.filter(x => x.id === id);

        return finalPrinting[0] ?? this.getPrinting('cheapest');
      } else {
        return this.getPrinting('cheapest');
      }
      
    }

    return this.printings[0];
  }

  checkEtbUntappedAlways(scryfallObj:any) {
    return !this.full_oracle.some((x='') => x.match(/.*enters the battlefield tapped\.*/gi))

    && !scryfallObj?.keywords!.includes('Hideaway');
  }

  checkEtbUntappedSometimes(scryfallObj:any) {
    return this.full_oracle.some((x='') => x.match(/.*enters the battlefield tapped\.*/gi) && (x.match(/.*if you don't.*/gi) || x.match(/.*tapped unless.*/gi) || x.match(/.*if you .*,.* enters the battlefield tapped/gi)));
  }

  checkEtbTapped(scryfallObj:any) {
    return this.full_oracle.some((x='') => x.match(/.*enters the battlefield tapped\.*/gi) && !(x.match(/.*if you don't.*/gi) || x.match(/.*tapped unless.*/gi) || x.match(/.*if you .*,.* enters the battlefield tapped/gi)));
  }

  checkHandAbility(scryfallObj:any) {
    return scryfallObj?.keywords?.some((keyword:string) => [
      'Plainscycling',
      'Islandcycling',
      'Swampcycling',
      'Mountaincycling',
      'Forestcycling',
      'Basic landcycling',

      'Cycling',
      'Channel',
      'Fortify',
      'Reinforce',
      'Transmute'
      ].includes(keyword));
  }

  checkStaticAbility(scryfallObj:any) {
    return [
      "Adventurers' Guildhouse",
      'Cathedral of Serra',
      'Mountain Stronghold',
      "Seafarer's Quay",
      'Unholy Citadel',

      'Catherdal of War',
      'Eye of Ugin',
      'Field of the Dead',
      'Halls of Mist',
      'Reliquary Tower',
      'Santcum of Ugin',
      'Seraph Sanctuary',
      'Sheltered Valley',
      'The Tabernacle at Pendrell Vale',
      'The World Tree',
      'Urborg, Tomb of Yawgmoth',
      'Valakut, the Molten Pinnacle',
      'Yavimaya, Cradle of Growth',
      'Emeria, the Sky Ruin'
    ].includes(scryfallObj.name);
  }

  checkEtbAbility(scryfallObj:any) {
    const etbAbility = new RegExp(/When .* enters the battlefield/gi);
    const etbOrDie = new RegExp(/When .* enters the battlefield, sacrifice it/gi);

    if(['Temple of the Dragon Queen'].includes(scryfallObj.name)) {
      return true;
    }

    return this.full_oracle.some(x => x?.match(etbAbility)) && !this.full_oracle.some(x => x?.match(etbOrDie));
  }

  checkActivatedAbility(scryfallObj:any) {
    // Regex for activated abilities that aren't mana abilities.
    const d = new RegExp(/({T}|{W}|{U}|{B}|{R}|{G}|{C}|{\d}).*: (?!(Add ({W}|{U}|{B}|{R}|{G}|{C})*))/ig);

    if(['Forbidden Orchard'].includes(scryfallObj.name)) {
      return true;
    }
    
    return !this.tag_set.isCreatureLand && !this.tag_set.isHandAbility && !this.full_oracle.some(x => x?.match(/storage counter/g)) &&
      !this.full_oracle.some(x => x?.match(/add an amount/g)) &&
      !this.full_oracle.some(x => x?.match(/storage counter/g)) &&
      !this.full_oracle.some(x => x?.match(/search your library for.*(land|Forest|Plains|Swamp|Island|Mountain) card/ig)) && 
      !scryfallObj?.keywords?.includes('Cycling') &&
      this.full_oracle.some(x => x?.match(d));
  }

  checkFetchLand(scryfallObj:any) {
    const r = new RegExp(/.*Search.*(land|basic|Forest|Plains|Swamp|Island|Mountain|gate).*/ig);
    return this.full_oracle.some(x => x?.match(r));
  }

  checkCreatureLand(scryfallObj:any) {

    if(scryfallObj.name === 'Dark Depths') {
      return true;
    }

    const r = new RegExp(/.*:.* (become|becomes) a \d\/\d .*creature.*\.*/ig);
    return this.full_oracle.some(x => x?.match(r)) || (scryfallObj?.type_line.includes('Creature') && scryfallObj?.layout !== 'modal_dfc') || scryfallObj?.keywords.includes('Morph');
  }

  checkSpellLand(scryfallObj:any) {
    return scryfallObj?.type_line.includes('//') && scryfallObj?.type_line.split('//')[1]?.includes('Land') && ['Planeswalker', 'Creature', 'Sorcery', 'Instant', 'Artifact', 'Enchantment'].some(x => scryfallObj?.type_line.split('//')[0].includes(x));
  }

  checkProducesExtra(scryfallObj:any) {
    const xCheck = new RegExp(/add x/ig);
    const multiCheck = new RegExp(/add ({W}|{U}|{B}|{R}|{G}|{C}){2,}/ig);
    const storageCheck = new RegExp(/storage counter/ig);
    const forEachCheck = new RegExp(/add ({W}|{U}|{B}|{R}|{G}|{C}).*for each/ig);
    const filterCheck = new RegExp(/({[A-Z\d/]+})+, {T}: Add/ig);

    // Special cases
    if(this.name === 'Nykthos, Shrine to Nyx') {
      return true;
    }

    return this.full_oracle.some(x => x?.match(multiCheck) || x?.match(xCheck) || x?.match(storageCheck) || x?.match(forEachCheck))
    && !this.full_oracle.some(x => x?.match(filterCheck) && !(x?.match(forEachCheck) || x?.match(xCheck)));
  }

  checkModal(scryfallObj:any) {
    return scryfallObj?.layout === 'modal_dfc';
  }

  pathWayHack(scryfallObj:any) {
    const n = scryfallObj.name;
    if(n.includes("Barkchannel Pathway")) {
      scryfallObj.produces.push('U')
    } else if(n.includes("Blightstep Pathway")) {
      scryfallObj.produces.push('R')
    } else if(n.includes("Branchloft Pathway")) {
      scryfallObj.produces.push('W')
    } else if(n.includes("Brightclimb Pathway")) {
      scryfallObj.produces.push('B')
    } else if(n.includes("Clearwater Pathway")) {
      scryfallObj.produces.push('B')
    } else if(n.includes("Cragcrown Pathway")) {
      scryfallObj.produces.push('G')
    } else if(n.includes("Darkbore Pathway")) {
      scryfallObj.produces.push('G')
    } else if(n.includes("Hengegate Pathway")) {
      scryfallObj.produces.push('U')
    } else if(n.includes("Needleverge Pathway")) {
      scryfallObj.produces.push('W')
    } else if(n.includes("Riverglide Pathway")) {
      scryfallObj.produces.push('R')
    }
    return scryfallObj;
  }

  generateTags(scryfallObj:any) {
    this.tag_set.entersUntappedAlways = this.checkEtbUntappedAlways(scryfallObj);
    this.tag_set.entersUntappedSometimes = this.checkEtbUntappedSometimes(scryfallObj);
    this.tag_set.entersTapped = this.checkEtbTapped(scryfallObj);
    this.tag_set.producesW = this.produces?.includes('W');
    this.tag_set.producesU = this.produces?.includes('U');
    this.tag_set.producesB = this.produces?.includes('B');
    this.tag_set.producesR = this.produces?.includes('R');
    this.tag_set.producesG = this.produces?.includes('G');
    this.tag_set.producesC = this.produces_colorless;

    this.tag_set.isPlains = this.type_line.includes("Plains");
    this.tag_set.isForest = this.type_line.includes("Forest");
    this.tag_set.isMountain = this.type_line.includes("Mountain");
    this.tag_set.isIsland = this.type_line.includes("Island");
    this.tag_set.isSwamp = this.type_line.includes("Swamp");
    this.tag_set.isBasic = this.tag_set.isPlains || this.tag_set.isForest || this.tag_set.isMountain || this.tag_set.isIsland || this.tag_set.isSwamp;

    this.tag_set.isCreatureLand = this.checkCreatureLand(scryfallObj);
    this.tag_set.isFetchLand = this.checkFetchLand(scryfallObj);
    this.tag_set.isSpellLand = this.checkSpellLand(scryfallObj);
    this.tag_set.isHandAbility = this.checkHandAbility(scryfallObj);
    this.tag_set.isActivatedAbility = this.checkActivatedAbility(scryfallObj);
    this.tag_set.isStaticAbility = this.checkStaticAbility(scryfallObj);
    this.tag_set.isEtbAbility = this.checkEtbAbility(scryfallObj);
    this.tag_set.isModal = this.checkModal(scryfallObj);
    this.tag_set.producesExtra = this.checkProducesExtra(scryfallObj);

    this.isUtility = [
      this.tag_set.isCreatureLand,
      this.tag_set.isFetchLand,
      this.tag_set.isSpellLand,
      this.tag_set.isHandAbility,
      this.tag_set.isActivatedAbility,
      this.tag_set.isStaticAbility,
      this.tag_set.isEtbAbility].some(x => x);
  }
}
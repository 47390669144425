import { ScryfallCard } from './scryfallCard';

export interface ManaListEntry {
  id: string,
  preferredPrintingId: string,
  card?:ScryfallCard|null,
  count: number,
  modifyDate: Date,
}

export class ManaList {
  id: string;
  title: string;
  author: string;
  createDate: Date;
  modifyDate: Date;
  cardList: Map<string, ManaListEntry>;

  static createFromJSON(blob:any) {
    const manaList = new ManaList(blob.id);

     Object.assign(manaList, blob);
     manaList.createDate = new Date(blob.createDate);
     manaList.modifyDate = new Date(blob.modifyDate);
     manaList.cardList = new Map(blob.cardList.value);
     return manaList;
  }

  constructor(id:string) {
    this.id = id;
    this.author = 'Timmy';
    this.title = 'New List';
    this.createDate = new Date();
    this.modifyDate = new Date();
    this.cardList = new Map();
  }

  addCard = (card:ScryfallCard, preferredPrintingId="") => {
    this.cardList.set(card.id, 
      {
        id: card.id,
        preferredPrintingId: preferredPrintingId ?? card.getPrinting('cheapest').id,
        card: card,
        count: 1,
        modifyDate: new Date()
      });
  }

  removeCard = (card:ScryfallCard) => {
    this.cardList.delete(card.id);
  }

  computeColorIdentityCount = (color:string) => {
    const colorIdentity = {
      W: 0,
      U: 0,
      B: 0,
      R: 0,
      G: 0,
    };

    for(const entry of Array.from(this.cardList.values())) {
      if(entry.card) {
        colorIdentity.W += (entry.card.color_identity.includes('W') ? entry.count : 0)
        colorIdentity.U += (entry.card.color_identity.includes('U') ? entry.count : 0)
        colorIdentity.B += (entry.card.color_identity.includes('B') ? entry.count : 0)
        colorIdentity.R += (entry.card.color_identity.includes('R') ? entry.count : 0)
        colorIdentity.G += (entry.card.color_identity.includes('G') ? entry.count : 0)
      }
    }
    return colorIdentity;
  }

  computeProduction = (color:string) => {
    return Array.from(this.cardList.values()).filter(x => x?.card?.produces.includes(color)).length;
  }

  computeEtbUntappedAlways = () => {
    return Array.from(this.cardList.values()).filter(x => x?.card?.tag_set.entersUntappedAlways).length;
  }

  computeEtbUntappedSometimes = () => {
    const list = Array.from(this.cardList.values()).filter(x => x?.card?.tag_set.entersUntappedSometimes);
    return list.length;
  }

  computeEtbTapped = () => {
    const list = Array.from(this.cardList.values()).filter(x => x?.card?.tag_set.entersTapped);
    return list.length;
  }

  computePrice = () => {
    return Array.from(this.cardList.values()).filter(x => x?.card?.getPrinting('printingId', x.preferredPrintingId).price_exists).reduce((t, entry) =>  {
      if(entry!.card!.getPrinting('printingId', entry.preferredPrintingId).price_exists) {
        return t + entry!.card!.getPrinting('printingId', entry.preferredPrintingId).price_usd
      } else {
        return t;
      }
    }, 0).toFixed(2);
  }

  computeCardsByProduction = () => {
    const productionMap = new Map<string, Array<ManaListEntry>>();
    for(const entry of Array.from(this.cardList.values())) {
      let productionKey = entry!.card!.produces.sort().join('');
      if (!productionKey) {
        if(entry!.card!.produces_colorless) {
          productionKey = 'C';
        }
      }
      if(productionKey && productionMap.has(productionKey)) {
        productionMap.get(productionKey)!.push(entry);
      } else {
        productionMap.set(productionKey, new Array(entry));
      }
    }
    return productionMap;
  }

  computeCardsByProductionCount = () => {
    const productionMap = new Map<string, Array<ManaListEntry>>();
    for(const entry of Array.from(this.cardList.values())) {
      const stringKey = entry!.card!.produces.join('');
      let productionKey;
      if (!stringKey) {
        productionKey = 0;

        if(entry!.card!.produces_colorless) {
          productionKey = .5;
        }
      } else {
        productionKey = stringKey.length;
      }

      productionKey = productionKey.toString();

      if(productionKey && productionMap.has(productionKey)) {
        productionMap.get(productionKey)!.push(entry);
      } else {
        productionMap.set(productionKey, new Array(entry));
      }
    }
    return productionMap;
  }

  computeCardsByTapStatus = () => {
    const tapMap = new Map<string, Array<ManaListEntry>>();
    tapMap.set('always', new Array());
    tapMap.set('sometimes', new Array());
    tapMap.set('never', new Array());
    for(const entry of Array.from(this.cardList.values())) {
      
      if(entry!.card!.tag_set.entersUntappedAlways) {
        tapMap.get('always')?.push(entry)
      }

      else if(entry!.card!.tag_set.entersUntappedSometimes) {
        tapMap.get('sometimes')?.push(entry)
      }

      else {
        tapMap.get('never')?.push(entry)
      }
    }
    return tapMap;    
  }
}